import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import '../styles/venice-prato.scss';
import LogoTrame from '../images/TDVBgroupImages/logo-trame.png';
import LogoVenice from '../images/TDVBgroupImages/logo-veniceBay.png';
import VeniceDescription from '../components/VeniceDescription/index';
import PratoDescription from '../components/PratoDescription/index';

const VenicePratoPage = () => {
  /* 
  const [isPratoOpen, setIsPratoOpen] = useState(false);
  const [isVeniceOpen, setIsVeniceOpen] = useState(false);
  const tlVenice = useRef(gsap.timeline({ paused: true }));
  const tlPrato = useRef(gsap.timeline({ paused: true }));
  */

  /* venice logo animation 
  const logoVeniceBay = useRef();
  useEffect(() => {
    gsap.set(logoVeniceBay.current, { autoAlpha: 0 });
  }, []);
  const veniceMouseHover = () => {
    gsap.fromTo(logoVeniceBay.current, { y: '40vh', autoAlpha: 0 }, { y: '25vh', autoAlpha: 1 });
  };
  const veniceMouseLeave = () => {
    gsap.to(logoVeniceBay.current, { y: '40vh', autoAlpha: 0 });
  }; */

  /* prato logo animation 
  const logoTrame = useRef();
  useEffect(() => {
    gsap.set(logoTrame.current, { autoAlpha: 0 });
  }, []);
  const pratoMouseHover = () => {
    gsap.fromTo(logoTrame.current, { y: '40vh', autoAlpha: 0 }, { y: '22vh', autoAlpha: 1 });
  };
  const pratoMouseLeave = () => {
    gsap.to(logoTrame.current, { y: '40vh', autoAlpha: 0 });
  };
  */

  /* venice open box animation
  const veniceOnClickOpen = () => {
    tlVenice.current.to('.logo-veniceBay', { autoAlpha: 0, display: 'none' }, '>');
    tlVenice.current.to('.prato', { scale: 0.5, autoAlpha: 0 }, '<');
    tlVenice.current.to('.prato', { width: '0vw' }, '>');
    tlVenice.current.to('.venice', { width: '100%' }, '>');
    tlVenice.current.to('.venice-description', { display: 'unset', autoAlpha: 1 }, '>');

    if (isVeniceOpen === false) {
      tlVenice.current.play();
      setIsVeniceOpen(true);
      return;
    }
  };

  const veniceOnClickClose = () => {
    if (isVeniceOpen === true) {
      tlVenice.current.reverse();
      setIsVeniceOpen(false);
    }
  };
  */

  /* prato open box animation 
  const pratoOnClickOpen = () => {
    tlPrato.current.to('.logo-trame', { autoAlpha: 0, display: 'none' }, '>');
    tlPrato.current.to('.venice', { autoAlpha: 0, scale: 0.5 }, '<');
    tlPrato.current.to('.venice', { width: '0vw' }, '>');
    tlPrato.current.to('.prato', { width: '100%' }, '>');
    tlPrato.current.to('.prato-description', { display: 'unset', autoAlpha: 1 }, '>');

    if (isPratoOpen === false) {
      tlPrato.current.play();
      setIsPratoOpen(true);
      return;
    }
  };

  const pratoOnClickClose = () => {
    if (isPratoOpen === true) {
      tlPrato.current.reverse();
      setIsPratoOpen(false);
    }
  };
  */

  return (
    <section className="venice-prato-section">
      <div className="main-container">
        {/* venice div */}
        <a className="venice" href="https://venicebay.it/" target="blank">
          <img className="logo-veniceBay" src={LogoVenice}></img>
        </a>

        {/* prato div */}
        <a className="prato" href="https://www.trame-digitali.it/" target="blank">
          <img className="logo-trame" src={LogoTrame}></img>
        </a>

        <p className="title"> PARTNERS </p>
      </div>
    </section>
  );
};

export default VenicePratoPage;
